.active-badge {
  background-color: #EBF4FD;
  border: 1px solid #EBF4FD;
  color: #0B73EB;
  text-transform: capitalize;
}

.active-badge>div {
  background-color: #0B73EB !important;
  border: 1px #0B73EB;
}
.btn {
  display: inline-flex;
  flex-shrink: 0;
  cursor: pointer;
  user-select: none;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
  line-height: 1em;
  min-height: 3rem;
  font-weight: 600;
  animation: button-pop var(--animation-btn,.25s) ease-out;
}

.ratingControl {
  position: relative;
  display: inline-flex;
  direction: rtl;
}
.ratingControl__radio {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}
.ratingControl__star {
  position: relative;
  display: block;
  height: 48px;
  width: 24px;
  cursor: pointer;
  overflow: hidden;
}
.ratingControl__star:nth-last-of-type(odd)::before, .ratingControl__star:nth-last-of-type(odd)::after {
  left: 0;
  -webkit-clip-path: polygon(50% 0%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
          clip-path: polygon(50% 0%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
}
.ratingControl__star:nth-last-of-type(even)::before, .ratingControl__star:nth-last-of-type(even)::after {
  right: 0;
  -webkit-clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%);
          clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%);
}
.ratingControl__star::before, .ratingControl__star::after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
}
.ratingControl__star::before {
  width: 200%;
  background-color: #aaa;
}
.ratingControl__star::after {
  background-color: #f2b600;
}
.ratingControl__star:hover::after, .ratingControl__star:hover ~ .ratingControl__star::after, .ratingControl__radio:checked ~ .ratingControl__star::after {
  width: 200%;
}
 
 
input:checked + label {
border-color: #fff;
background-color: #fff;
}
 
.badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  height: 1.25rem;
  font-size: .875rem;
  line-height: 1.25rem;
  width: fit-content;
  padding-left: .563rem;
  padding-right: .563rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
  border-radius: var(--rounded-badge,1.9rem)
}

.spinners{
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}